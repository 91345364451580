var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FilterCollection from "~/components/collection-manage/all-case/filter-collection.vue";
import FollowAllQuery from "~/components/collection-manage/follow-all-query.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { State, namespace, Getter } from "vuex-class";
import { Watch } from "vue-property-decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import NumberRange from "~/components/common/number-range.vue";
import { CollectionData } from "~/config/types/collection-data";
import { LeaveCaseService } from "~/services/business-service/leave-case.service";
import { StatusConfigService } from "~/services/manage-service/status-config.service";
var collectionManageModule = namespace("collection-manage");
var DetentionCase = /** @class */ (function (_super) {
    __extends(DetentionCase, _super);
    function DetentionCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handSet = [];
        _this.loading = {
            state: false,
        };
        _this.switchCaseType = "1";
        _this.collectionCount = {};
        _this.money = "";
        _this.currentCase = {};
        _this.deptIds = [];
        _this.queryParamsModel = {
            batchNumber: "",
            personalName: "",
            departCode: "",
            cardNo: "",
            caseNumber: "",
            phone: "",
            collectionRecordCount: "",
            city: "",
            retireCaseDays: "",
            stage: "",
            collectStatus: "",
            caseStatus: "",
            comment: "",
            delegationDate: [],
            endCaseDate: [],
            currentCollectorName: "",
            address: "",
            employerName: "",
            followDays: "",
            leftAmt: {
                min: "",
                max: "",
            },
            overduePeriods: {
                min: "",
                max: "",
            },
            overdueDays: {
                min: "",
                max: "",
            },
            overdueAmtTotal: {
                min: "",
                max: "",
            },
            caseType: "",
        };
        _this.selectionList = [];
        _this.dataSet = [];
        _this.buttonStatus = [];
        _this.collectionStatus = [];
        _this.collectorDate = [];
        _this.dialog = {
            detail: false,
        };
        _this.rules = {
            certificateNo: [
                {
                    min: 4,
                    message: "请输入至少4位的身份证号",
                    trigger: "blur",
                },
            ],
            phone: [
                {
                    min: 4,
                    message: "请输入至少4位的手机号",
                    trigger: "blur",
                },
            ],
            cardNo: [
                {
                    min: 6,
                    message: "请输入至少6位的卡号",
                    trigger: "blur",
                },
            ],
        };
        return _this;
    }
    DetentionCase.prototype.onRouteChange = function (val) {
        if (!val)
            return;
        this.handleResetForm();
        this.queryParamsModel.principalId = val.params.principalId;
        this.refreshData();
        this.getConfigByPrin();
    };
    DetentionCase.prototype.cellStyle = function (_a) {
        var _this = this;
        var row = _a.row;
        if (this.collectionStatus && row.collectStatus) {
            var statusArr = row.collectStatus.split(",");
            var findArr_1 = [];
            statusArr.forEach(function (x) {
                var a = _this.collectionStatus.find(function (status) { return x === status.name; });
                if (a) {
                    findArr_1.push(a);
                }
            });
            if (findArr_1.length > 0) {
                row.color = findArr_1.reduce(function (a, b) {
                    return b.sort > a.sort ? b : a;
                }).color;
            }
        }
        if (!row.color)
            return;
        return {
            color: row.color + " !important",
        };
    };
    DetentionCase.prototype.getConfigByPrin = function () {
        var _this = this;
        this.loading.state = true;
        var param = "COLLECT_STATUS";
        this.statusConfigService.findAllConfigNoPage(param, this.loading).subscribe(function (data) {
            _this.collectionStatus = data;
        });
    };
    DetentionCase.prototype.mounted = function () {
        if (this.collectorList.userNameList) {
            this.collectorDate = this.collectorList.userNameList.map(function (x) {
                return {
                    value: x.name,
                    userName: x.userName,
                };
            });
        }
    };
    DetentionCase.prototype.handleResetForm = function () {
        this.queryParamsModel.departCode = "";
        this.queryParamsModel.batchNumber = "";
        this.queryParamsModel.personalName = "";
        this.queryParamsModel.cardNo = "";
        this.queryParamsModel.phone = "";
        this.queryParamsModel.caseNumber = "";
        this.queryParamsModel.collectionRecordCount = "";
        this.queryParamsModel.city = "";
        this.queryParamsModel.retireCaseDays = "";
        this.queryParamsModel.stage = "";
        this.queryParamsModel.collectStatus = "";
        this.queryParamsModel.caseStatus = "";
        this.queryParamsModel.comment = "";
        this.queryParamsModel.delegationDate = [];
        this.queryParamsModel.endCaseDate = [];
        this.queryParamsModel.currentCollectorName = "";
        this.queryParamsModel.address = "";
        this.queryParamsModel.employerName = "";
        this.queryParamsModel.followDays = "";
        this.queryParamsModel.overdueAmtTotal = [];
        this.queryParamsModel.leftAmt = [];
        this.queryParamsModel.overduePeriods = [];
        this.queryParamsModel.overdueDays = [];
        this.deptIds = [];
    };
    DetentionCase.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.collectorDate.filter(this.createFilter(queryString)) : this.collectorDate;
        cb(results);
    };
    DetentionCase.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
    };
    DetentionCase.prototype.refreshData = function () {
        var _this = this;
        this.leaveCaseService.queryLeaveCases(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    DetentionCase.prototype.onFilterSearch = function (val) {
        this.queryParamsModel.caseFiller = val.caseFiller;
        this.refreshData();
    };
    DetentionCase.prototype.onSwitchCase = function (type) {
        switch (type) {
            case "1":
                this.queryParamsModel.groupType = "GROUP_CASE";
                this.refreshData();
                break;
            case "2":
                this.queryParamsModel.groupType = "PERSONAL_CASE";
                this.refreshData();
                break;
            default:
                this.queryParamsModel.groupType = "GUIDE_CASE";
                this.refreshData();
                break;
        }
    };
    /**
     * 机构改变
     */
    DetentionCase.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.queryParamsModel.departCode = valueArray[valueArray.length - 1];
    };
    DetentionCase.prototype.onBatchNumberClick = function (currentCollection) {
        var otherData = {
            sort: this.sortService.clone(),
            caseType: currentCollection.caseType,
        };
        this.queryParamsModel.casePool = "LEAVE_CASE"; //留案案件池
        var data = new CollectionData(this.queryParamsModel.principalId, currentCollection.id, this.queryParamsModel, otherData);
        this.openExecutionPage(data);
    };
    __decorate([
        Dependencies(StatusConfigService)
    ], DetentionCase.prototype, "statusConfigService", void 0);
    __decorate([
        Dependencies(PageService)
    ], DetentionCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], DetentionCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(LeaveCaseService)
    ], DetentionCase.prototype, "leaveCaseService", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], DetentionCase.prototype, "custConfigService", void 0);
    __decorate([
        collectionManageModule.Action
    ], DetentionCase.prototype, "openExecutionPage", void 0);
    __decorate([
        Getter
    ], DetentionCase.prototype, "departmentData", void 0);
    __decorate([
        State
    ], DetentionCase.prototype, "collectorList", void 0);
    __decorate([
        Watch("$route", {
            immediate: true,
        })
    ], DetentionCase.prototype, "onRouteChange", null);
    DetentionCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                FilterCollection: FilterCollection,
                FollowAllQuery: FollowAllQuery,
                NumberRange: NumberRange,
            },
        })
    ], DetentionCase);
    return DetentionCase;
}(Vue));
export default DetentionCase;
